import React, {
  createContext,
  ReactElement,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from "react";
import { initialState, reducer, IInitialState, reducerType } from "./reducer";
import { ActionType, setInvoice, setRaitingSetting, setSalonRatingSetting } from "./actions";
import { useRouter } from "next/router";
import { useObjectVal } from "react-firebase-hooks/database";
import { raitingValuesInDB } from "@/hooks/useRating";
import { getSalonBranchId, getSalonId, setInvoiceId } from "@/utils/localStorage";
import { db, ref } from "@/firebase";
import { useQuery } from "@tanstack/react-query";
import { RaitingService } from "@/services/raitingService";
import { InvoiceService } from "@/services";
import { SalonRatingSettingService } from "@/services/SalonRatingSettingService";

export type ContextValueType = [IInitialState, Dispatch<ActionType>];

const Context = createContext<ContextValueType>([initialState, () => void {}]);

export function StoreProvider(props: { children: ReactElement | JSX.Element }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { salonId, salonBranchId } = state;
  const router = useRouter();
  const [value, loading, error] = useObjectVal<raitingValuesInDB>(
    ref(db, `salons/${salonId || getSalonId()}/${salonBranchId || getSalonBranchId()}/invoice-staff-rating`)
  );

  const { data: ratingSetting } = useQuery(['raitingSetting'], () =>
    RaitingService.getRaitingSettingBySalonId(salonId ?? getSalonId()!)
  )

  useEffect(() => {
    ratingSetting && dispatch(setRaitingSetting(ratingSetting))
  }, [dispatch, ratingSetting])

  const getInvoice = async (invoiceId: string) => {
    const res = await InvoiceService.getInvoiceById(invoiceId);
    dispatch(setInvoice(res.data))
    if (res.data.customer) {
      router.asPath === '/' && router?.push('/xac-nhan-hoa-don')
    } else {
      router.asPath === '/' && router?.push('/customer/so-dien-thoai')
    }
  }

  useEffect(() => {
    if (value) {
      if (value.invoiceId) {
        setInvoiceId(value.invoiceId)
        getInvoice(value.invoiceId)
        return;
      }
      if (value.invoiceId === '') {
        router.asPath !== '/' && router?.push('/')
      }
    }
  }, [value])


  return (
    <Context.Provider value={[state, dispatch]}>
      {props.children}
    </Context.Provider>
  );
}

export const useStore = () => useContext(Context);
